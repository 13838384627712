<template>
  <div class="main">
    <div class="chat-card">
      <!--  style="height: 800px;" -->
      <el-scrollbar>
        <div v-if="historyList&&historyList.length>0" class="chat-content">
          <div v-for="(item,index) in historyList" :key="index" class="content-main">
            <div class="picture">
              <img :src="item.type == 1 ? 'https://newoss.zhulong.com/forum/202206/02/46/103546ckyxy5lcwwkes8du.png':avator" alt="" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
              <p v-if="item.type == 1">小鱼儿</p>
            </div>
            <div class="content-body">
              <div>
                <div v-if="item.type==1" class="message incoming">
                  <div class="setImg" v-html="item.content"></div>
                  <div v-if="item.guifanShow" class="laiyuan">
                    <div>
                      <p>规范原文：</p>
                      <div class="gfyw" v-html="item.guifanInfo.replace(/\n/g, '<br>')"></div>
                    </div>
                  </div>
                  <div v-if="index!=list.length-1||!btn_disabled" class="copy">
                    <div>
                      <div v-if="item.locsource!='规范库'">答案来源于网络</div>
                      <div v-else>
                        <!-- :style="!item.guifanShow?'':'visibility: hidden;'" -->
                        <div v-if="!item.guifanShow" class="see_gf" @click="lookS(item,1)">查看规范<i class="el-icon-arrow-down"></i></div>
                        <div v-else class="see_gf" @click="lookS(item,2)">查看规范<i class="el-icon-arrow-up"></i></div>
                      </div>
                    </div>
                    <!-- <div class="btn" @click="copy(item.initial_content)">
                      <img src="https://newoss.zhulong.com/forum/202404/16/17/1713258049228690.png" alt="">
                      <span>复制</span>
                    </div> -->
                  </div>
                </div>
                <div v-if="item.type==2" class="message outgoing">
                  <p>{{ item.content }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-top">
          <div class="picture">
            <img src="https://newoss.zhulong.com/forum/202206/02/46/103546ckyxy5lcwwkes8du.png" alt="">
            <p>小鱼儿</p>
          </div>
          <div class="chat-body">
            <div class="name">您好，我是小鱼儿</div>
            <div class="dec">作为筑龙<span>AI建筑知识问答助手</span>，</div>
            <div class="dec">我能为你提供建筑知识相关问题的解决方案，和<span>设计规范</span>中的相关回答。</div>
            <div class="dec" style="margin: 20px 0 0;">你可以试着问我：</div>
            <div class="questionlist">
              <div v-for="(item,index) in questionlist" :key="index" @click="!btn_disabled&&send(item.content)">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="chat-content">
          <div v-for="(item,index) in list" :key="index" class="content-main">
            <div class="picture">
              <img :src="item.type == 1 ? 'https://newoss.zhulong.com/forum/202206/02/46/103546ckyxy5lcwwkes8du.png':avator" alt="" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
              <p v-if="item.type == 1">小鱼儿</p>
            </div>
            <div class="content-body">
              <div>
                <div v-if="item.type==1" class="message incoming">
                  <div v-if="index==list.length-1&&loading">
                    <section class="dots-container">
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </section>
                  </div>
                  <div v-else>
                    <div style="color: #333;" class="setImg" v-html="item.content"></div>
                    <div v-if="item.guifanShow" class="laiyuan">
                      <div>
                        <p>规范原文：</p>
                        <div class="gfyw" v-html="item.guifanInfo.replace(/\n/g, '<br>')"></div>
                      </div>
                    </div>
                    <div v-if="index!=list.length-1||!btn_disabled" class="copy">
                      <div v-if="!locsourceLoading">
                        <div v-if="item.locsource!='规范库'">答案来源于网络</div>
                        <div v-else>
                          <!-- :style="!item.guifanShow?'':'visibility: hidden;'" -->
                          <div v-if="!item.guifanShow" class="see_gf" @click="lookS(item,1)">查看规范<i class="el-icon-arrow-down"></i></div>
                          <div v-else class="see_gf" @click="lookS(item,2)">查看规范<i class="el-icon-arrow-up"></i></div>
                        </div>
                      </div>
                      <div v-else>
                        加载中...
                      </div>
                      <div class="btn">
                        <img v-if="item.isLiked" class="zan1" src="https://newoss.zhulong.com/forum/202409/20/48/154848bss2woifuhuj5eh2.png" alt="" @click="clickLike(item,index)">
                        <img v-else class="zan1" src="https://newoss.zhulong.com/forum/202409/18/28/110228ij1dwro05y9phluk.png" alt="" @click="clickLike(item,index)">
                        <span class="sline"></span>
                        <img v-if="item.isUnliked" class="zan2" src="https://newoss.zhulong.com/forum/202409/20/8/154908vzv4xpr5qxjahb2l.png" alt="" @click="clickUnlike(item,index)">
                        <img v-else class="zan2" src="https://newoss.zhulong.com/forum/202409/18/59/110259aidaafoxxejk6pns.png" alt="" @click="clickUnlike(item,index)">
                        <span class="sline"></span>
                        <img src="https://newoss.zhulong.com/forum/202404/16/17/1713258049228690.png" alt="" @click="copy(item.initial_content)">
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="item.type==2" class="message outgoing">
                  <p>{{ item.content }}</p>
                </div>
              </div>
              <!-- 踩 -->
              <div v-if="item.type == 1 && (showUnlike && unlike_item_index == index)" class="unlike_box">
                <div class="tags_box">
                  <span v-for="(tag,tidx) in unlikeTags" :key="tidx" class="tag_item" :class="{'active_tag':unlike_tag_id==tag.title}" @click="selectUnlikeTag(tag)">{{ tag.title }}</span>
                </div>
                <div class="input_box">
                  <el-input v-model="unlike_reason" class="reason_ipt" placeholder="（可选）希望您能写下对此答案感到不满意的原因，也可以写下您的建议来帮助我们改进，谢谢！"></el-input>
                  <span class="submit_btn" @click="submitUnlike">提交</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div class="chat-bottom">
        <div class="boxhidden"></div>
        <div class="question-body">
          <!-- :autosize="{ minRows: 3 }" -->
          <el-input
            v-model="message"
            :rows="3"
            type="textarea"
            placeholder="请输入您的问题"
          >
          </el-input>
          <div class="send" :style="btn_disabled ? 'cursor: not-allowed;':''" @click="!btn_disabled&&sendBtnFunc()">
            <img src="https://newoss.zhulong.com/forum/202404/16/14/1713249195295295.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cookies from 'js-cookie'
import MarkdownIt from 'markdown-it'
import Clipboard from 'clipboard'
import hljs from 'highlight.js'
import { chat2, gethistorymsg, getGuifanInfo, gethistorymsgV2, setAilog } from '@/api/chatGPT'
import { postThread, attendGroup } from '@/api/public.js'

export default {
  data() {
    return {
      avator: '',
      loading: false, // 加载动画
      locsourceLoading: true,
      btn_disabled: false, // 控制按钮是否可以点击
      hisrorymsg: 0, // 首次提问需传0，之后传1
      message: '',
      copy_text: '',
      questionlist: [
        {
          'id': 1,
          'content': '建筑行业有哪些高含金量的证书可以考？'
        },
        {
          'id': 2,
          'content': '工程造价是怎么计算的？'
        },
        {
          'id': 3,
          'content': '在民用建筑设计统一标准中，是如何规定建筑分类的？'
        },
        {
          'id': 4,
          'content': '楼梯扶手的高度应该是多少？'
        }
      ],
      alldata: '',
      historyList: [],
      list: [],
      displayText: '',
      typingSpeed: 20, // 字符显示的速度，单位毫秒
      timer: null, // 计时器
      // unlike
      showUnlike: false,
      unlikeTags: [
        {
          id: 3,
          title: '功能出错'
        },
        {
          id: 4,
          title: '没有帮助'
        },
        {
          id: 5,
          title: '回答错误'
        },
        {
          id: 6,
          title: '回复质量低'
        },
        {
          id: 7,
          title: '回答不完整'
        }
      ],
      unlike_reason: '',
      unlike_tag_id: '',
      unlike_item_index: 0,
      // 获取传递过来的问题
      keywords: this.$route.query.words || ''
    }
  },
  computed: {

  },
  created() {
    var uid = cookies.get('uid')
    var url = window.location.origin + '/ucenter/login?redirect=' + location.href
    if (!uid) {
      window.open(url, '_self')
      return
    }
    this.getAvator(cookies.get('uid'))
  },
  mounted() {
    this.adaptiveBox()
    // 获取历史记录
    this.gethistorymsgV2()
    window.addEventListener('resize', this.adaptiveBox)
  },
  methods: {
    // 提问框自适应位置
    adaptiveBox() {
      var windowHeight = window.innerHeight || document.documentElement.clientHeight
      console.log(windowHeight, '--------')
      var a = document.getElementsByClassName('chat-card')[0]
      var b = document.getElementsByClassName('el-scrollbar')[0]
      // if (windowHeight - 400 > 200) {
      //   a.style = 'height:' + (windowHeight - 200) + 'px'
      //   b.style = 'height:' + (windowHeight - 200) + 'px'
      // } else {
      //   a.style = 'height: 200px'
      //   b.style = 'height: 200px'
      // }
      a.style = 'height:' + (windowHeight - 200) + 'px'
      b.style = 'height:' + (windowHeight - 200) + 'px'
    },
    gethistorymsgV2() {
      console.log('---------------')
      gethistorymsgV2().then(res => {
        if (res.errNo == 0) {
          var list = res.result || []
          console.log(list, 'list')
          list.forEach(element => {
            const sysmessage = element.sysmessage || {}
            const sysmessage_info = sysmessage.info || []
            const firstInfo = sysmessage_info.length > 0 ? sysmessage_info[0] : {}
            this.historyList.push(
              {
                type: 2,
                content: element.message
              }, {
                type: 1,
                content: this.renderMarkdown(element.sysmessage.answer || ''),
                locsource: sysmessage.locsource || '',
                gf_info: firstInfo,
                keyword: sysmessage.keyword || '',
                guifanInfo: '',
                guifanShow: false
              }
            )
          })
        }
      }).then(() => {
        this.scorellToBottom()
        // 传参提问
        if (this.keywords && this.keywords.length > 0) {
          this.send(this.keywords)
        }
      })
    },
    sendBtnFunc() {
      this.send()
    },
    send(val) {
      if (val) {
        this.message = val
      }
      console.log(this.message, 'this.message')
      if (this.message) {
        this.list.push({
          'content': this.message,
          'type': 2
        })
        var message = this.message
        this.message = ''
        this.btn_disabled = true
        setTimeout(() => {
          var obj = {
            'content': '', // md转之后的内容
            'initial_content': '', // 接口返回的初始化内容
            'link_url': '',
            'gf_info': {},
            'keyword': '',
            'info': '',
            'locsource': '',
            'guifanInfo': '',
            'guifanShow': false,
            'type': 1
          }
          this.list.push(obj)
          this.loading = true
          this.alldata = []
          this.scorellToBottom()
          this.chat2(message)
          // 需求：第一次调接口是0，调完改成1
          if (this.hisrorymsg == 0) {
            this.hisrorymsg = 1
          }
        }, 500)
      } else {
        this.$message({
          message: '请输入内容',
          type: 'warning'
        })
      }
    },
    chat2(message) {
      this.eventSource = new EventSource(window.location.origin + `/ucenter/prod-api/api/aiv2/chatV2?message=${encodeURIComponent(message)}&hisrorymsg=${this.hisrorymsg}&search=1`)
      this.eventSource.onmessage = (event) => {
        this.loading = false
        // 当接收到消息时，解析数据并更新messages数组
        const data = JSON.parse(event.data)
        if (data.type === 'load_complete') {
          // 数据加载完成的操作
          console.log('数据加载完成')
          this.btn_disabled = false
          this.locsourceLoading = false
          const info = data.data.info || []
          if (info && info.length > 0) {
            this.list[this.list.length - 1].gf_info = data.data.info[0]
          } else {
            this.list[this.list.length - 1].gf_info = {}
          }
          this.list[this.list.length - 1].locsource = data.data.locsource
          this.list[this.list.length - 1].keyword = data.data.keyword
        } else {
        // console.error(data);
          // this.messages.push(event.data);
          var data_str = data.data.answer || ''
          this.alldata = data_str
          // var new_data_str = data_str.replace(/\n/g, '<br>')
          this.list[this.list.length - 1].initial_content = this.alldata
          this.list[this.list.length - 1].content = this.renderMarkdown(this.alldata)
          if (data.data.locsource) {
            this.locsourceLoading = true
            this.btn_disabled = false
          }
          if (data.data.info && data.data.info.length > 0 && data.data.locsource === '规范库') {
            const info = data.data.info || []
            if (info && info.length > 0) {
              this.list[this.list.length - 1].gf_info = data.data.info[0]
            } else {
              this.list[this.list.length - 1].gf_info = {}
            }
            this.list[this.list.length - 1].locsource = data.data.locsource
            this.list[this.list.length - 1].keyword = data.data.keyword
          }
          this.scorellToBottom()
        }
      }
      this.eventSource.onerror = (error) => {
        console.error('SSE connection failed:', error)
        this.btn_disabled = false
        this.locsourceLoading = false
        this.eventSource.close()
        this.scorellToBottom()
        // 处理错误，例如尝试重新连接或显示错误消息
        // this.messages.push({ id: Date.now(), text: '连接失败，尝试重新输入问题。' });
      }
    },
    // markdown渲染，highlight.js高亮
    renderMarkdown(text) {
      const md = new MarkdownIt({
        highlight: function(str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return `<pre class="hljs"><code>${hljs.highlight(str, { language: lang }).value}</code></pre>`
            } catch (__) {}
          }

          return `<pre class="hljs"><code>${md.utils.escapeHtml(str)}</code></pre>`
        }
      })
      return md.render(text)
    },
    // 将聊天窗口滚动到最底部
    scorellToBottom() {
      var element = document.getElementsByClassName('el-scrollbar__wrap')[0] // 获取页面中所有类名为 'chat-content' 的元素的第一个元素
      element.scrollTop = element.scrollHeight // 将该元素的滚动位置设置为其内容的高度，实现滚动到最底部
      // var chatBody = document.getElementsByClassName('chat-body')[0];
      // if (chatBody.scrollTop + chatBody.clientHeight >= chatBody.scrollHeight) {
      //   // 当滚动条位于底部时，执行滚动到底部的操作
      //   chatBody.scrollTop = chatBody.scrollHeight;
      // }
    },
    // 查看规范
    lookS(item, type) {
      console.log(item, 'item')
      if (type == 1) {
        if (!item.guifanInfo) {
          var info = {
            keyword: item.keyword
          }
          // 将接口返回的拼在一起
          info = { ...info, ...item.gf_info }
          getGuifanInfo(info).then(res => {
            if (res.errNo == 0) {
              const result = res.result || {}
              const info_text = result.info || ''
              item.guifanInfo = info_text
              item.guifanShow = true
            }
          }).catch(() => {
            this.$message.error('获取规范失败')
          })
        } else {
          item.guifanShow = true
        }
      } else {
        item.guifanShow = false
      }
    },
    // 复制
    copy(text) {
      // text = text.replace(/<[^>]*>/g, '')
      text = text.replace(/[#*>+_`-]/g, '').replace(/!\[.*\]\(.*\)/g, '').replace(/\[.*\]\(.*\)/g, '').replace(/\*\*\*/g, '').replace(/\*\*[^*]\*\*/g, '').replace(/\*[^\\*]\*/g, '').replace(/__[^_]__/g, '').replace(/_[^_]_/g, '')
      var textarea = document.createElement('textarea')// 创建input对象
      var currentFocus = document.activeElement// 当前获得焦点的元素
      document.body.appendChild(textarea)// 添加元素
      textarea.value = text
      textarea.focus()
      if (textarea.setSelectionRange) {
        textarea.setSelectionRange(0, textarea.value.length)
      } else { textarea.select() }
      try {
        this.flag = document.execCommand('copy')// 执行复制
      } catch (eo) {
        this.flag = false
      }
      document.body.removeChild(textarea)// 删除元素
      currentFocus.focus()
      this.$message.success('复制成功')
      return this.flag
    },
    // 点赞
    clickLike(item, index) {
      console.log(item, 'item')
      const question = this.list[index - 1].content
      const info = {
        action: 'd',
        type: '',
        msg: '',
        q: question,
        a: item.content
      }
      setAilog(info).then(res => {
        if (res.errNo == 0) {
          this.$set(item, 'isLiked', true)
          this.$message.success('点赞成功')
        }
      })
    },
    // 踩
    clickUnlike(item, index) {
      if (this.showUnlike) {
        // 取消
        this.unlike_item_index = 0
        this.showUnlike = false
      } else {
        this.unlike_item_index = index
        this.showUnlike = true
      }
    },
    // 选择不喜欢的标签
    selectUnlikeTag(val) {
      this.unlike_tag_id = val.title
    },
    // 关闭弹窗
    closeUnlikePop() {
      this.showUnlike = false
      this.unlike_tag_id = ''
      this.unlike_reason = ''
    },
    // 提交
    submitUnlike() {
      if (this.unlike_tag_id.length == 0) {
        return this.$message.error('请选择不喜欢的原因')
      }
      const question = this.list[this.unlike_item_index - 1].content
      const cur_item = this.list[this.unlike_item_index]
      const answer = cur_item.initial_content // 历史记录不能用这个
      const unlike_tag_id = this.unlike_tag_id
      const unlike_reason = this.unlike_reason
      const info = {
        action: 'c',
        type: unlike_tag_id,
        msg: unlike_reason,
        q: question,
        a: answer
      }
      setAilog(info).then(res => {
        if (res.errNo == 0) {
          this.closeUnlikePop()
          this.$set(cur_item, 'isUnliked', true)
          this.$message.success('反馈成功')
          // 对踩，进行模拟发帖
          const guifanInfo = cur_item.guifanInfo || ''
          this.sendThread(unlike_tag_id, question, answer, guifanInfo, unlike_reason)
        }
      })
    },
    // 生成头像
    getAvator(uid) {
      const domain = 'https://newoss.zhulong.com/tfs/'
      let num = uid
      const n = 9
      let len = num.toString().length
      while (len < n) {
        num = '0' + num
        len++
      }
      uid = num
      const dir1 = uid.substr(0, 3)
      const dir2 = uid.substr(3, 2)
      const dir3 = uid.substr(5, 2)
      const time = parseInt(new Date().getTime() / 1000) + ''
      const ava = domain + dir1 + '/' + dir2 + '/' + dir3 + '/' + uid.substr(-2) + '_avatar_big.jpg?t=' + time
      this.avator = ava
    },
    // 发帖，反馈
    sendThread(unlike_tag_id, question, answer, guifanInfo, unlike_reason) {
      if (!cookies.get('uid')) {
        // 由于关注和发帖接口是需要登录，在此做个判断
        return
      }
      const gid = '856'
      const title = '【AI建筑问答】' + unlike_tag_id
      var message = '【提问】' + question + '<br/>' + '【答案】' + answer + '<br/>' + '【规范原文】' + guifanInfo + '<br/>' + '【反馈】' + '#' + unlike_tag_id + ' ' + unlike_reason
      // 调关注小组的接口
      attendGroup({ agency_id: cookies.get('agency_id') || '2', group_id: gid }).then(res => {
        console.log(res)
        const dataInfo = {
          gid: gid,
          tid: '',
          title: title,
          message: message,
          genre: '',
          filetype: ''
        }
        postThread(dataInfo).then(res => {})
      })
    }

  }

}
</script>
<style lang="scss" scoped>
*{
  margin: 0;
  padding: 0;
  text-align: left;
}
.main{
  margin: 0 0 0;
  padding: 100px 0 0;
}
.chat-card {
  width: 835px;
  // height: 800px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  /deep/.el-scrollbar__wrap{
    overflow-x: hidden;
  }
  .chat-top,.content-main,.chat-bottom{
    display: flex;
    align-items: flex-start;
  }
  .picture{
    width: 36px;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 5px;
    img{
      width: 28px;
    }
    p{
      width: 36px;
      font-size: 12px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .chat-top{
    height: 252px;
    margin-bottom: 20px;
    .chat-body{
      width: 780px;
      height: 252px;
      display: flex;
      flex-flow: column;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      padding: 15px;
      .name{
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 26.06px;
        color: rgba(211, 44, 44, 1);
        text-align: left;
        vertical-align: top;
        margin-bottom: 11px;
      }
      .dec{
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 17.38px;
        color: rgba(78, 78, 78, 1);
        line-height: 22px;
        span{
          color: #ee2e2e;
        }
      }
      .questionlist{
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        div{
          padding: 0 0 0 15px;
          line-height: 40px;
          width: 360px;
          height: 40px;
          opacity: 1;
          border-radius: 7px;
          background: rgba(254, 239, 239, 1);
          margin-top: 10px;
          color: #000;
          cursor: pointer;
        }
      }
    }
  }
  .chat-content{
    padding-bottom: 50px;
    flex: 1;
    .content-body{
      width: 780px;
      .message {
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 5px;
      }
      .incoming {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 11px  rgba(0, 0, 0, 0.17);
        .copy{
          display: flex;
          justify-content: space-between;
          text-align: right;
          margin-right: 4px;
          border-top: 1px solid #dfdfdf;
          margin-top: 10px;
          padding-top: 8px;
          .see_gf{
            cursor: pointer;
          }
          .btn{
            padding-right: 8px;
            display: inline-block;
            display: flex;
            align-items: center;
            img{
              width: 18px;
              display: block;
              cursor: pointer;
            }
            span{
              color: #999;
              font-size: 12px;
            }
            .sline{
              margin: 0 10px;
              color: #999;
              display: inline-block;
              height: 20px;
              border-right: 1px solid rgba(205, 205, 205, 1);
            }
            .zan1 {
              display: block;
              width: 18px;
            }
            .zan2 {
              display: block;
              width: 18px;
            }
          }
        }
      }
      .outgoing {
        border-radius: 5px;
        background: rgba(241, 241, 241, 1);
        box-shadow: 0px 0px 11px  rgba(0, 0, 0, 0.17);
      }
      .unlike_box {
        padding: 10px;
        border-radius: 5px;
        background-color: #d9d9d9;
        .tags_box {
          .tag_item {
            cursor: pointer;
            display: inline-block;
            margin-right: 15px;
            width: 90px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            border-radius: 8px;
            border: 1px solid #aaa;
          }
          .active_tag {
            color: #ee2e2e;
            border: 1px solid #ee2e2e;
          }
        }
        .input_box {
          margin-top: 10px;
          display: flex;
          align-items: center;
          .submit_btn {
            cursor: pointer;
            margin-left: 10px;
            display: inline-block;
            width: 50px;
            height: 28px;
            line-height: 28px;
            border-radius: 5px;
            text-align: center;
            background-color: #ee2e2e;
            color: #fff;
          }
        }
      }
    }
  }
  .chat-bottom{
    height: 120px;
    margin-left: 46px;
    .question-body{
      width: 780px;
      height: 120px;
      margin-top: 10px;
      opacity: 1;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      /deep/.el-textarea__inner {
        resize: none; /* 禁止用户调整文本域的大小 */
        border: none;
      }
      .send{
        width: 50px;
        height: 30px;
        border-radius: 13px;
        background: #ee2e2e;
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        cursor: pointer;
        margin: 5px 20px 0 0;
        img{
          width: 18px;
          display: block;
        }
      }
    }
  }
}
.laiyuan{
  border-top: 1px solid #dfdfdf;
  color: #999;
  font-size: 13px;
  margin-top: 10px;
  padding-top: 8px;
}
// 加载动画
.dots-container {
  display: flex;
  align-items: center;
  height: 21px;
  width: 100%;
}

.dot {
  height: 5px;
  width: 5px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: #dfdfdf;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    // transform: scale(0.8);
    background-color: #dfdfdf;
    box-shadow: 0 0 0 0 rgba(233, 234, 235, 0.7);
  }

  50% {
    // transform: scale(1.2);
    background-color: #333;
    box-shadow: 0 0 0 2px rgba(178, 212, 252, 0);
  }

  100% {
    // transform: scale(0.8);
    background-color: #dfdfdf;
    box-shadow: 0 0 0 0 rgba(233, 234, 235, 0.7);
  }
}
.setImg{
  word-break: break-all;
  /deep/img{
    width: 100%;
  }
}
.gfyw{
  color: #333;
  font-size: 14px;
  word-break: break-all;
  /deep/img{
    width: 100%;
  }
}
</style>
